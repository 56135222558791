import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appInfiniteScroll]',
  standalone: true,
})
export class InfiniteScrollDirective implements AfterViewInit {
  @Input() loadMoreElement;
  @Output() intersectionDone = new EventEmitter<void>();

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.implementInfiniteScroll();
  }

  implementInfiniteScroll() {
    const options = {
      root: this.el.nativeElement,
      rootMargin: '15px',
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.intersectionDone.emit();
        }
      });
    }, options);
    observer.observe(this.loadMoreElement);
  }
}
